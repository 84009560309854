import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as loginAction from "../../../processes-domain/login/actions";
import * as registerAction from "../../../processes-domain/register/register-actions";
//import { hubRunner } from "../../../hubs/hubs-factory";
import Login from "../views/Login";

const {
  REACT_APP_RETURN_URL_DEFAULT = "https://informburo.tass.ru"
} = process.env;

class LoginContainer extends Component {
  componentWillMount() {
    let params = new URLSearchParams(this.props.location.search.toLowerCase());
    let promo = params.get("promocode");
    let partnerId = params.get("partnerid");
    let returnUrl = params.get("returnurl");
    this.props.initRegister(promo, partnerId, returnUrl);
  }

  render = () => {
    return (
      <Login
        loginUc={this.props.loginUc}
        sendForm={(values, actions) =>
          this.props.login(
            values,
            actions,
            this.props.history,
            //this.props.location.state
            //  ? this.props.location.state.returnUrl
            //  : null
            this.props.initial &&
              this.props.initial.initialProduct &&
              this.props.initial.initialProduct.returnUrl
              ? this.props.initial.initialProduct.returnUrl
              : null
          )
        }
        {...this.props}
      />
    );
  };
}

const mapStateToProps = ({
  globalState: { lang },
  registerState: {
    initial //: { initialProduct }
  }
}) => ({ lang, initial });

const mapDispatchToProps = dispatch => ({
  initRegister: bindActionCreators(registerAction, dispatch).initRegister,
  loginUc: bindActionCreators(loginAction, dispatch).loginUc,
  login: (values, actions, history, returnUrl) => {
    bindActionCreators(loginAction, dispatch).login(
      values.email,
      values.pass,
      values.rememberMe,
      () => {
        //hubRunner();
        if (returnUrl) {
          //history.push(returnUrl);
          window.location.href = returnUrl;
        } else {
          //history.push(`${PART_ROUTE}/`);
          window.location.href = REACT_APP_RETURN_URL_DEFAULT; //"https://proto.rspp-centre.com";
        }
      },
      error => {
        actions.setSubmitting(false);
        actions.setErrors(error);
      }
    );
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
);
